.container {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin: -9px 0;
}

.title {
  font-size: 15px;
  color: #4b4b4b;
  line-height: 21px;
}

.subtitle {
  font-size: 10px;
  color: #0c4f3f;
  font-weight: 700;
  line-height: 14px;
}

@media screen and (max-width: 1000px) {
  .container {
    margin: 0;
  }
}
