.container {
  position: relative;
  width: 100%;
  z-index: 12;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.month {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #4b4b4b;
}

.buttons {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: #0c4f3f;
  background: #d3dfdc;
  border: none;
  cursor: pointer;
  box-shadow: 0px 2px 8px 0px #bebebe26;
}

.button:first-child {
  margin-right: 8px;
}
