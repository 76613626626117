.container {
  font-family: 'Roboto', sans-serif;
}

.table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0 4px;
  word-break: break-word;
}

.thead > .row {
  height: 61px;
}

.thead > .row > .cell {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  padding: 0 20px;
  line-height: 21px;
  color: #ababab;
  background-color: #f6f6f6;
}

.thead > .row > .cell:first-child {
  padding-left: 40px;
  border-radius: 8px 0 0 8px;
}

.thead > .row > .cell:last-child {
  padding-right: 40px;
  border-radius: 0 8px 8px 0;
}

.thead > .row > .cell:only-child {
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 8px;
}

.tbody > .row {
  background-color: #ffffff;
  cursor: pointer;
}

.tbody > .row:hover {
  background-color: #f6f6f6;
}

.tbody > .row > .cell {
  font-size: 15px;
  font-weight: 500;
  padding: 22px;
  line-height: 21px;
  color: #4b4b4b;
}

.tbody > .row > .cell:first-child {
  padding-left: 40px;
  border-radius: 8px 0 0 8px;
}

.tbody > .row > .cell:last-child {
  padding-right: 40px;
  border-radius: 0 8px 8px 0;
}

.tbody > .row > .cell:only-child {
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 8px;
}

.emptyBody {
  height: 14px;
}

.alignLeft {
  text-align: left;
}

.strokeRow {
  border-radius: 8px;
  outline: 1px solid #0c4f3f;
}

.mobileItem {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.mobileItem_wrapper {
  display: grid;
  justify-items: left;
}

.mobileItem_header {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  line-height: 16px;
  color: #ababab;
  margin-bottom: 6px;
}

.mobileItem_content {
  display: grid;
  align-items: end;
}

@media screen and (max-width: 1000px) {
  .thead {
    display: none;
  }

  .emptyBody {
    display: none;
  }

  .tbody > .row > .cell:only-child,
  .tbody > .row > .cell {
    padding: 20px 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  .tbody > .row:hover {
    background-color: #ffffff;
  }
}
