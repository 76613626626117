.container {
  font-family: 'Roboto', sans-serif;
}

.table {
  margin-top: 40px;
}

.label {
  display: flex;
  align-items: center;
}

.labelMain {
  font-family: 'Roboto', sans-serif !important;
  color: #4b4b4b !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0 !important;
}

.labelSub {
  display: inline-flex !important;
  font-family: 'Roboto', sans-serif !important;
  color: #0c4f3f !important;
  margin-left: auto !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  font-size: 10px !important;
  line-height: 14px !important;
  border-radius: 8px !important;
  padding: 4px 8px !important;
  border: 1px solid #0c4f3f !important;
}

@media screen and (max-width: 1000px) {
  .table {
    margin-top: 32px;
  }

  .labelSub {
    position: absolute;
    top: 0;
    right: 0;
  }
}
