.container {
  font-family: 'Roboto', sans-serif;
}

.table {
  margin-top: 40px;
}

.tableLabelRow {
  margin: -7px 0;
}

@media screen and (max-width: 1000px) {
  .table {
    margin-top: 32px;
  }

  .tableLabelRow {
    margin: 0;
  }
}
