.search {
  display: flex;
  align-items: center;
  padding: 0 24px;
  background-color: #f6f6f6;
  border-radius: 8px;
  border: 1px solid #ababab4d;
  box-shadow: 0px 2px 3px 0px #0000000a;
}

.searchInput {
  width: 100%;
  height: 52px;
  margin-left: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #4b4b4b;
  background-color: transparent;
  border: none;
  appearance: none;
  outline: none;
}

.searchInput::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #ababab;
}
