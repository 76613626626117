.container {
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  text-align: center;
  margin: 0px 10px;
  max-width: 448px;
  width: 100%;
}

.formIcon {
  width: 100%;
  margin-bottom: 64px;
}

.formIcon img {
  width: 100%;
}

.formInput {
  margin-bottom: 28px !important;
}

.button {
  height: 68px !important;
  margin-top: 13px !important;
  font-size: 18px !important;
  border-radius: 12px !important;
}

.link {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 89%;
  text-align: center;
  color: #0c4f3f;
}

.link:hover {
  text-decoration: underline;
  text-decoration-color: #0c4f3f;
}

@media screen and (max-width: 500px) {
  .formIcon {
    margin-bottom: 48px;
  }
}
