.container {
  position: relative;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.label {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #4b4b4b;
}

.inputContainer {
  width: 100%;
  height: 32px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 400;
  margin-top: 16px;
  color: #4b4b4b;
  border: 1px solid #ababab52;
}

.input {
  width: 100%;
  height: 100%;
  font: inherit;
  border: none;
  appearance: none;
  outline: none;
  border-radius: 8px;
  padding: 0 16px;
  background: none;
}

.input::placeholder {
  font-size: 12px;
  color: #ababab99;
  font-weight: 400;
}

.items {
  display: flex;
  margin-top: 12px;
  flex-wrap: wrap;
  gap: 12px;
}

.items.empty {
  margin-top: 0;
}
