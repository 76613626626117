.tab {
  display: flex;
  align-items: center;
}

.title {
  font: inherit;
}

.info {
  padding: 2px 6px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  background-color: #ababab;
  margin-left: 12px;
  border-radius: 8px;
}

.info.orange {
  background-color: #f3ac23;
}
