.status {
  display: flex;
  align-items: center;
  margin: -15px 0;
}

.statusLabel {
  display: inline-block;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  white-space: nowrap;
  word-break: auto-phrase;
  border-radius: 20px;
}

.confirmation {
  background: #ced8fc;
  color: #163bf5;
}

.in_process {
  background: #f3e3fc;
  color: #cb75f1;
}

.part_shipped {
  background: #f9e2ce;
  color: #ee8a41;
}

.ready {
  background: #dafaf0;
  color: #57b794;
}

.shipped_closed {
  background: #e8e8ea;
  color: #4b4b4b;
}

.canceled {
  background: #bc3a3133;
  color: #bc3a31;
}

@media screen and (max-width: 1590px) {
  .part_shipped.break {
    max-width: 82px;
    white-space: normal;
  }

  .shipped_closed.break {
    max-width: 82px;
    white-space: normal;
  }
}

@media screen and (max-width: 1000px) {
  .status {
    margin: 0;
  }

  .part_shipped.break,
  .shipped_closed.break {
    max-width: none;
  }
}
