.container {
  font-family: 'Roboto', sans-serif;
}

.reportsContainer {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(582px, 1fr));
  margin-top: 40px;
}

@media screen and (max-width: 1520px) {
  .reportsContainer {
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  }
}

@media screen and (max-width: 1000px) {
  .reportsContainer {
    margin-top: 32px;
  }
}
