.container {
  position: relative;
  font-family: 'Roboto', sans-serif;
}

.fixedScrollbarX {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18px;
  background-color: transparent;
  z-index: 100;
  overflow-x: auto;
}

.scrollbarInnerX {
  width: 100%;
}

.tabs {
  margin-top: 28px;
}

.content {
  position: relative;
}

.tableContainer {
  font-family: 'Roboto', sans-serif;
  margin-top: 40px;
}

.tableHeaderContainer {
  position: sticky;
  top: 0;
  z-index: 2;
  overflow-x: auto;
  scrollbar-width: none;
}

.tableHeader {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.tableBodyContainer {
  scrollbar-width: none;
  overflow-x: auto;
}

.tableHeaderContainer::-webkit-scrollbar,
.tableBodyContainer::-webkit-scrollbar {
  display: none;
}

.tableBody {
  width: 100%;
  border-spacing: 0 4px;
}

.tableHeader > .thead > .row {
  height: 61px;
}

.tableHeader > .thead > .row > .cell {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  padding: 0 10px;
  line-height: 21px;
  color: #ababab;
  background-color: #f6f6f6;
}

.tableBody > .tbody > .row {
  background-color: #ffffff;
  cursor: pointer;
}

.tableBody > .tbody > .row:hover,
.tableBody > .tbody > .row:hover > .tbodyFirstCellSticky,
.tableBody > .tbody > .row:hover > .tbodySecondCellSticky {
  background-color: #f6f6f6;
}

.tableBody > .tbody > .row > .cell {
  font-size: 15px;
  font-weight: 500;
  padding: 22px;
  line-height: 21px;
  color: #4b4b4b;
}

.tableBody > .tbody > .row > .cell:first-child,
.tableHeader > .thead > .row > .cell:first-child {
  padding-left: 40px;
  border-radius: 8px 0 0 8px;
}

.tableBody > .tbody > .row > .cell:last-child,
.tableHeader > .thead > .row > .cell:last-child {
  padding-right: 40px;
  border-radius: 0 8px 8px 0;
}

.tableBody > .tbody > .row > .cell:only-child,
.tableHeader > .thead > .row > .cell:only-child {
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 8px;
}

.productCell {
  max-width: 230px;
  min-width: 230px;
}

.warehouseCell,
.balanceCell {
  max-width: 135px;
  min-width: 135px;
  text-align: center;
}

.balanceCell {
  color: #0c4f3f !important;
}

.tableBody > .tbody > .row > .balanceCell {
  font-weight: 700 !important;
}

.theadFirstCellSticky {
  position: sticky;
  z-index: 3 !important;
  left: 0;
}

.tbodyFirstCellSticky {
  position: sticky;
  z-index: 1;
  left: 0;
  background-color: #ffffff;
}

.theadSecondCellSticky {
  position: sticky;
  z-index: 3 !important;
  left: 230px;
}

.tbodySecondCellSticky {
  position: sticky;
  z-index: 1;
  left: 230px;
  background-color: #ffffff;
}

.cursorMove {
  cursor: move;
}

.emptyBody {
  height: 14px;
}

.emptyData {
  height: 50vh !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

@media screen and (max-width: 1000px) {
  .productCell {
    max-width: 150px;
    min-width: 150px;
  }

  .warehouseCell,
  .balanceCell {
    max-width: 80px;
    min-width: 80px;
  }

  .theadSecondCellSticky {
    position: static;
  }

  .tbodySecondCellSticky {
    position: static;
  }

  .tableHeader > .thead > .row > .cell:first-child,
  .tableBody > .tbody > .row > .cell:first-child {
    padding-left: 20px;
  }

  .tableHeader > .thead > .row > .cell:last-child,
  .tableBody > .tbody > .row > .cell:last-child {
    padding-right: 20px;
  }

  .tableHeader > .thead > .row > .cell:only-child,
  .tableBody > .tbody > .row > .cell:only-child {
    padding-left: 20px;
    padding-right: 20px;
  }
}
