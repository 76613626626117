.container {
  position: relative;
  font-family: 'Roboto', sans-serif;
  width: 347px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: -2px 0px 4px 0px #00000014, 0px 2px 4px 0px #00000014;
  border-radius: 8px;
}

.message {
  padding-right: 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  color: #4b4b4b;
}

.action {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 20px;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.button {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #4b4b4b;
  line-height: 21px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.button.red {
  margin-left: 16px;
  color: #bc3a31;
}

@media screen and (max-width: 500px) {
  .container {
    width: 100%;
  }
}
