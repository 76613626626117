.row {
  background-color: #ffffff;
}

.cell {
  padding: 32px 40px 22px;
  border-radius: 8px;
}

.wrapper:last-child:not(:only-child) {
  margin-top: 40px;
}

.tableRow {
  background-color: #fcfcfc !important;
  cursor: default !important;
}

@media screen and (max-width: 1000px) {
  .cell {
    padding: 20px 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  .wrapper:last-child:not(:only-child) {
    margin-top: 32px;
  }
}
