.container {
  border-radius: 8px;
  max-width: 806px;
  max-height: 520px;
  background-color: #fcfcfc;
  box-shadow: 0px 4px 12px 0px #0000001f;
}

.wrapper {
  padding: 20px 32px;
}

.header {
  padding: 0 32px 20px 32px;
  margin: 0 -32px;
}

.columnsContainer {
  max-height: 427px;
  padding: 0 20px 0px 32px;
  margin-right: -32px;
  margin-left: -32px;
  overflow-y: auto;
  overflow-x: hidden;
}

.columnsBase {
  --columns-width: 242px;
  column-gap: 56px;
}

.columns_1 {
  column-count: 1;
  width: calc(var(--columns-width) * 1);
}

.columns_2 {
  column-count: 2;
  width: calc(var(--columns-width) * 2);
}

.columns_3 {
  column-count: 3;
  width: calc(var(--columns-width) * 3);
}

.list {
  list-style: none;
}

.listItem {
  margin-left: -8px;
}

.listItem:not(:last-child) {
  margin-bottom: 20px;
}

.backIcon {
  margin-left: 8px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
  .container {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    z-index: 1300;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
  }

  .container.open {
    display: block;
  }

  .wrapper {
    padding: 0;
  }

  .header {
    margin: 0;
    padding: 20px 16px;
  }

  .columnsContainer {
    height: calc(100dvh - 112px);
    max-height: 100%;
    margin-right: 0;
    margin-left: 0;
    padding: 0 16px 20px 16px;
    scrollbar-gutter: stable;
  }

  .columns_1 {
    column-count: 1;
    width: 100%;
  }
}
