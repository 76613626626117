.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 16px;
  background-color: #fcfcfc;
  transition: transform 225ms ease-in-out;
}

.container.hidden {
  transform: translateY(-100%);
}

.container.visible {
  transform: translateY(0);
}

.leftPanel,
.rightPanel {
  position: absolute;
}

.leftPanel {
  left: 16px;
}

.rightPanel {
  display: flex;
  align-items: center;
  gap: 16px;
  right: 16px;
}

.rightPanel svg {
  width: 20px;
  height: 20px;
  fill: #0c4f3f;
}

.leftPanel,
.rightPanel {
  top: 36px;
}
