.container {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}

.checkbox {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.customCheckbox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  border: 2px solid #ababab;
}

.customCheckbox.error {
  border: 2px solid #bc3a31;
}

.text {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  color: #4b4b4b;
  margin-left: 4px;
  cursor: pointer;
}

.customCheckbox_icon {
  display: none;
}

.checkbox:checked ~ .customCheckbox {
  border-color: #0c4f3f;
}

.checkbox:checked ~ .customCheckbox > .customCheckbox_icon {
  display: block;
}

.shake {
  animation: shaker 0.2s linear;
}

@keyframes shaker {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0px);
  }
}
