:root {
  --date-color: #d3dfdc;
}

.react-datepicker {
  font-family: 'Roboto', sans-serif;
  border-radius: 8px;
  overflow: hidden;
  border: none;
  background-color: #fcfcfc;
  padding: 20px 24px;
  box-shadow: 0px 4px 12px 0px #0000001f;
}

.react-datepicker.small {
  padding: 20px 21px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  padding: 0;
  border-bottom: none;
  background-color: #fcfcfc;
}

.react-datepicker__month-container {
  background-color: #fcfcfc;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__current-month {
  padding: 14px 0px;
  font-weight: 500;
  font-size: 18px;
}

.react-datepicker__current-month::first-letter {
  text-transform: uppercase;
}

.react-datepicker__navigation {
  top: 6px;
}

.react-datepicker__navigation-icon::before {
  height: 5px;
  width: 5px;
  border-width: 2px 2px 0 0;
  border-color: #fff;
}

.react-datepicker__week:not(:last-child) {
  margin-bottom: 8px;
}

.react-datepicker__day-names {
  margin-top: 16px;
  margin-bottom: 8px;
}

.react-datepicker__day-name {
  font-weight: 400;
  font-size: 12px;
  color: #ababab;
  width: 33px;
  margin: 0;
  line-height: 37px;
}

.react-datepicker.small .react-datepicker__day-name {
  width: 30px;
  line-height: 32px;
}

.react-datepicker__day {
  font-weight: 400;
  font-size: 15px;
  color: #4b4b4b;
  width: 33px;
  margin: 0;
  line-height: 37px;
  border-radius: 8px;
}

.react-datepicker.small .react-datepicker__day {
  width: 30px;
  font-size: 12px;
  line-height: 32px;
}

.react-datepicker__day:hover {
  border-radius: 8px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  border-radius: 8px;
  background: var(--date-color);
  color: #4b4b4b !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-range:hover {
  border-radius: 8px;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 8px;
  background: var(--date-color);
}

.react-datepicker__day:not(
    .react-datepicker__day--range-end,
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range
  ).react-datepicker__day--keyboard-selected {
  background-color: transparent;
  color: #000000 !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--selected:hover {
  background: var(--date-color);
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background: var(--date-color);
  border-radius: 8px;
}

.react-datepicker__day--outside-month,
.react-datepicker__day--disabled {
  font-weight: 400;
  font-size: 15px;
  color: #ababab33;
}

.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 2px;
}

@media screen and (max-width: 1000px) {
  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
  }

  .react-datepicker,
  .react-datepicker__month-container,
  .react-datepicker-popper {
    width: 100%;
  }

  .reports .react-datepicker-popper,
  .reports .react-datepicker,
  .reports .react-datepicker__month-container {
    width: auto;
  }

  .reports .react-datepicker__day-names,
  .reports .react-datepicker__week {
    column-gap: 0;
  }
}
