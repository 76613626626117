.container {
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  margin: -10px 0;
}

.container.option {
  margin: -3px 0;
}

.photo {
  overflow: hidden;
  flex-shrink: 0;
}

.photo,
.photoImage {
  width: 32px;
  height: 41px;
}

.photoImage {
  object-fit: contain;
}

.text {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.name {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #4b4b4b;
  word-break: break-word;
}

.clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.code {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #0c4f3f;
}

.custom {
  white-space: nowrap;
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  .container,
  .container.option {
    margin: 0;
  }

  .text.tooltip {
    padding-right: 62px;
  }
}
