.container {
  font-family: 'Roboto', sans-serif;
}

.header {
  display: flex;
  align-items: center;
}

.headerTitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.72);
  line-height: 89%;
  margin-right: 10px;
}

.headerIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
