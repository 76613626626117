.container {
  font-family: 'Roboto', sans-serif;
}

.wrapper {
  margin-top: 40px;
  width: 710px;
}

.wrapperSmall {
  margin-top: 40px;
  width: 343px;
}

.fields {
  margin-top: 4px !important;
  margin-bottom: 40px !important;
}

.formItem {
  font-family: 'Roboto', sans-serif;
}

.option {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  margin-left: 5px;
  padding: 5px 0;
}

.optionInfo {
  font-size: 15px;
  color: #4b4b4b;
  font-weight: 400;
  line-height: 21px;
}

.optionSubInfo {
  font-size: 10px;
  color: #0c4f3f;
  font-weight: 700;
  line-height: 14px;
}

.searchField {
  width: 343px !important;
}

.dateBeforeField {
  right: 0;
}

@media screen and (max-width: 1000px) {
  .wrapper,
  .wrapperSmall {
    width: 100%;
    margin-top: 32px;
  }

  .fields {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 500px) {
  .searchField {
    width: 100% !important;
  }
}
