.container {
  font-family: 'Roboto', sans-serif;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 210px);
  text-align: center;
}

.text {
  margin-top: 20px;
  color: #4b4b4b;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}
