.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  padding: 32px 20px;
  width: 244px;
  background-color: #ffffff;
  transition: width 225ms ease-in-out;
  border-radius: 0px 24px 24px 0;
  box-shadow: 1px 2px 4px 0px #00000014;
}

.container.collapse {
  width: 88px;
}

.container.nested {
  border-radius: 0;
}

.header {
  position: relative;
  height: 40px;
  margin-left: 10px;
  margin-bottom: 28px;
  overflow: hidden;
}

.headerText {
  padding-top: 10px;
  margin-left: 16px;
}

.nav {
  height: calc(100% - 68px);
  padding-right: 14px;
  margin-right: -20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav::-webkit-scrollbar {
  width: 6px;
  border-radius: 8px;
}

.nav::-webkit-scrollbar-thumb {
  background: inherit;
  border-radius: 8px;
  cursor: default;
}

.nav:hover::-webkit-scrollbar-thumb {
  background-color: #4b4b4b;
}

.links {
  list-style: none;
}

.item:not(:last-child) {
  margin-bottom: 8px;
}

.link,
.nestedLink {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  font-family: 'Roboto', 'sans-serif';
  font-weight: 500;
  font-size: 15px;
  text-decoration: none;
  color: #4b4b4b;
  fill: currentColor;
  line-height: 24px;
  cursor: pointer;
  white-space: nowrap;
  transition: color 0.2s, background-color 0.2s;
}

.link.active,
.nestedLink.active {
  color: #0c4f3f;
  font-weight: 700;
  background: #0c4f3f2e;
}

.link:not(.active):hover,
.nestedLink:not(.active):hover {
  color: #0c4f3f;
  background: #0c4f3f2e;
}

.linkIcon,
.collapseIcon {
  flex-shrink: 0;
}

.linkText {
  margin-left: 16px;
  transition: opacity 0.3s ease;
}

.nestedLinkText {
  margin-left: 16px;
}

.container.collapse .linkText {
  opacity: 0;
  visibility: hidden;
}

.collapseIcon {
  transform: rotate(180deg);
}

.container.collapse .collapseIcon {
  transform: rotate(0deg);
}

.collapseIcon_mobile,
.collapseIconNestedMenu_mobile {
  position: absolute;
  left: 0;
  transform: rotate(180deg);
}

.collapseIcon_mobile {
  top: 12px;
}

.collapseIconNestedMenu_mobile {
  left: 20px;
}

.nestedMenu {
  position: absolute;
  top: 0;
  left: 101%;
  width: 0;
  height: 100%;
  background-color: #ffffff;
  border-radius: 0px 24px 24px 0;
  overflow-x: hidden;
  transition: width 225ms ease-in-out;
}

.nestedMenu.open {
  width: 244px;
}

.nestedMenuWrapper {
  padding: 0 20px;
}

.nestedMenuHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', 'sans-serif';
  font-weight: 700;
  font-size: 15px;
  margin: 48px 0px 28px 0;
  color: #4b4b4b;
  fill: currentColor;
}

.nestedIcon {
  margin-left: 17px;
}

@media screen and (max-width: 1000px) {
  .container {
    padding: 20px;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
  }

  .container.collapse {
    width: 100%;
    transform: translateX(-100%);
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    overflow: visible;
  }

  .nestedMenu {
    left: 0;
    border-radius: 0;
    transition: none;
  }

  .nestedMenu.open {
    width: 100%;
  }

  .nestedMenuHeader {
    margin: 32px 0px;
  }

  .linkText {
    transition: none;
  }

  .container.collapse .linkText {
    opacity: 1;
    visibility: visible;
  }
}
