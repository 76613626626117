.table tr {
  height: 48px !important;
}

.table td {
  font-size: 12px !important;
  line-height: 14px !important;
  padding-right: 14px !important;
  padding-left: 14px !important;
}

.table td:first-child {
  padding-left: 16px !important;
}

.table td:last-child {
  padding-right: 16px !important;
}

.table tbody:nth-of-type(1) {
  height: 4px;
}

.table tbody td {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

@media screen and (max-width: 1000px) {
  .table {
    margin: 0 2px;
  }

  .table td:first-child {
    padding-left: 12px !important;
  }

  .table td:last-child {
    padding-right: 12px !important;
  }

  .table tbody td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
