.loader div {
  left: 48.5px;
  top: 19px;
  position: absolute;
  animation: loader linear 0.6711409395973155s infinite;
  background: #474747;
  width: 3px;
  height: 12px;
  border-radius: 1.5px / 4.5600000000000005px;
  transform-origin: 1.5px 31px;
}

.loader div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.587248322147651s;
  background: #474747;
}

.loader div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -0.5033557046979866s;
  background: #474747;
}

.loader div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.41946308724832215s;
  background: #474747;
}

.loader div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.33557046979865773s;
  background: #474747;
}

.loader div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.2516778523489933s;
  background: #474747;
}

.loader div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.16778523489932887s;
  background: #474747;
}

.loader div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.08389261744966443s;
  background: #474747;
}

.loader div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: 0s;
  background: #474747;
}

.loaderSpinner {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.loader {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.loader div {
  box-sizing: content-box;
}

@keyframes loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
