.container {
  position: relative;
  font-family: 'Roboto', sans-serif;
}

.table {
  margin-top: 20px;
}

.tabs {
  margin-top: 28px;
}

@media screen and (max-width: 1000px) {
  .tabs {
    margin-top: 20px;
  }
}
