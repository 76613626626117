.menu {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  padding: 16px 24px;
  right: 0;
  cursor: pointer;
}

.icon {
  margin-right: 14px;
}

.text {
  display: inline-block;
  width: 66px;
}

.code {
  color: rgba(0, 0, 0, 0.4);
  margin-left: 24px;
}
