.container {
  position: relative;
}

.label {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 10px;
  padding: 4px 8px;
  color: #0c4f3f;
  line-height: 14px;
  border-radius: 8px;
  border: 1px solid #0c4f3f;
  background: transparent;
}

.icon {
  position: absolute;
  height: 12px;
  width: 12px;
  top: -8px;
  right: -10px;
  fill: #0c4f3f;
}

@media screen and (max-width: 1000px) {
  .container {
    position: absolute;
    right: 0;
    top: -15px;
  }
}
