.container {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 8px 10px;
  border: 1px solid #ababab52;
  background-color: #fcfcfc;
  color: #4b4b4b;
  border-radius: 8px;
  box-shadow: -1px 2px 3px 0px #00000008;
}

.row {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
