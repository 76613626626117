.container {
  max-width: 1093px;
}

.containerItem:last-child {
  margin-top: 40px;
}

.grid {
  display: grid;
  grid-template-columns: 343px 710px;
  column-gap: 40px;
  margin-top: 28px;
}

.fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.fieldsVertical {
  display: grid;
  gap: 24px;
}

.fileUpload {
  position: relative;
  width: 100%;
  height: 284px;
  padding: 8px;
  font-family: 'Roboto', sans-serif;
  border-radius: 8px;
  border: 1px solid #ababab;
  background: #fcfcfc;
  box-shadow: 0px 2px 3px 0px #00000014;
}

.fileUpload.disabled {
  pointer-events: none;
}

.fieldLabel {
  position: absolute;
  top: -9px;
  left: 24px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 8px;
  color: #ababab;
  background-color: #fcfcfc;
}

.fileUpload_imageWrapper,
.fileUpload_image {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.fileUpload_image {
  object-fit: cover;
}

.checkbox {
  margin-top: 16px;
  text-align: right;
}

@media screen and (max-width: 1420px) {
  .container {
    width: 100%;
  }

  .grid {
    grid-template-columns: 1fr 2fr;
  }
}

@media screen and (max-width: 1000px) {
  .containerItem:last-child {
    margin-top: 32px;
  }

  .grid {
    gap: 24px;
  }

  .grid,
  .fields {
    grid-template-columns: 1fr;
  }
}
