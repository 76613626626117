.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 32px 20px;
}

.content {
  height: 100%;
  padding: 20px 16px 20px 32px;
  scrollbar-gutter: stable;
  overflow-y: auto;
}

.content > .item:not(:first-child) {
  margin-top: 32px;
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 20px 32px;
}

.item {
  display: flex;
  flex-direction: column;
}

.checkboxes {
  margin-top: 12px;
  margin-left: -8px;
}

@media screen and (max-width: 1000px) {
  .header,
  .content,
  .footer {
    padding: 20px 16px;
  }
}
